/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import { Disqus } from 'gatsby-plugin-disqus';

import 'prismjs/themes/prism-okaidia.css';

import SEO from '../components/Seo/Seo';
import { colors, breakpoints } from '../styles/variables';
import { PostType } from '../types/common';

const PostWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
`;

const CenterColumn = styled.div`
  width: 100%;
  padding: 4rem 2rem;

  @media ${breakpoints.tabletPortrait} {
    padding: 4rem 5rem;
  }

  @media ${breakpoints.tabletLandscape} {
    padding: 4rem 0;
    width: 76rem;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const Heading = styled.h2`
  font-size: 3rem;
  color: ${colors.brandColor};
`;

const Subheader = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const ReadTime = styled.span`
  font-size: 1.4rem;
  color: ${colors.darkShade};
`;

const Author = styled.span`
  font-size: 1.4rem;
  color: ${colors.darkShade};
  margin-left: 1rem;
`;

const Date = styled.span`
  font-size: 1.4rem;
  color: ${colors.darkShade};
  margin-left: 1rem;
`;

const Body = styled.div`
  font-size: 2rem;
  width: 100%;
  margin: 4rem 0;
  line-height: 1.6;

  p {
    margin-top: 2rem;
  }

  ul {
    padding-top: 2rem;
  }

  code.language-text {
    font-size: 1.6rem;
    padding: 0.1rem 0.5rem;
    background-color: #272822;
    color: ${colors.lightShade};
  }

  .gatsby-highlight {
    font-size: 1.6rem;
    margin: 2rem 0;
  }
`;

const propTypes = {
  pageContext: PropTypes.shape({
    post: PostType.isRequired,
    slug: PropTypes.string.isRequired,
    previous: PostType,
    next: PostType,
  }).isRequired,
};
const BlogPost = ({ pageContext }) => {
  const {
    slug,
    post: { id, html, published, featured, tags, title, words, description, image },
    previous,
    next,
  } = pageContext;

  console.log({ featured, tags, description, image, previous, next });

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  // TODO:
  // disqus - comments
  // other posts
  // info about the author, date
  // tags
  // add lines to prismjs
  // move UI to separate component

  return (
    <>
      <SEO title={`DEVBRAINS | Blog - ${title}`} />
      <PostWrapper>
        <CenterColumn>
          <TopContainer>
            <Heading>{title}</Heading>
            <Subheader>
              <ReadTime>{`${Math.ceil(words / 200)} min`}</ReadTime>
              <Author>Michał Winiarski</Author>
              <Date>{published.substring(0, 10)}</Date>
            </Subheader>
          </TopContainer>
          <Body dangerouslySetInnerHTML={{ __html: html }} />
          <Disqus
            config={{
              url: `https://devbrains.pl/blog${slug}`,
              identifier: id,
              title,
            }}
          />
        </CenterColumn>
      </PostWrapper>
    </>
  );
};

BlogPost.propTypes = propTypes;

export default BlogPost;
